<script>
  import villageAddressSelect from '@/component/villageAddressSelectX/index.vue';

  export default {
    components: {
      // eslint-disable-next-line vue/no-unused-components
      villageAddressSelect
    },
    data() {
      return {};
    },
    created() {

    },
    methods: {
      villageInfo(val) {
        // console.log(val, this.Getindex, 'vvvvv');
        this.$emit('villageInfo', val, this.Getindex);
      }
    },
    props: {
      cityDistrictVillage: {
        type: Object,
        default: () => {}
      },
      Getindex: {
        type: Number
      }
    }
  };
</script>
<template>
  <div>
    <villageAddressSelect
            @villageInfo="villageInfo"
            isVillage
            :cityDistrictVillage="cityDistrictVillage"
    ></villageAddressSelect>
  </div>
</template>

<style lang="less" scoped>

</style>
