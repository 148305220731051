const baseInfo = [
  // 基地信息
  {
    'architectureInfoList': [
      // 建筑信息List
      {
        'area': null,
        //建筑面积
        'buildTime': '',
        // 建设时间
        'groundArea': null,
        'isComplete': true,
        // 建设/备案手续是否齐全 1:齐全 0:不齐全
        'isProperty': true,
        // 是否有产权 1:是 0:否
        'layers': null,
        // 层数
        'modality': null,
        // 建筑形式
        'propertyName': '',
        // 产权名称
        'recordTime': '',
        // 备案时间
        'sort': 0,
        'undergroundArea': null
      }
    ],
    'AreaChange': [], // 多选框土地类型
    'baseAddress': '', // 基地详细地址
    'baseArea': '', //基地面积
    'baseDistribution': [], // 基地分布 1:核心区 2:保护区 3:其他区域
    'coreArea': null, // 核心区面积
    'distributionArea': null, // 其他区域面积
    'baseId': '', // 基地id
    'baseName': '', //基地名称
    'baseNature': null, // 基地性质: 1固定 2临时
    'buildArea': null, // 配套总建筑面积（baseAddressm2）
    'buildingNumber': null, // 楼栋数量
    'cityId': '', // 基地市id
    'cityName': '', // 基地市名称
    'contractEndDate': '', // 合同结束时间
    'contractStartDate': '', // 合同开始时间
    'districtId': '', // 基地区id
    'districtName': '', // 基地区名称
    'dryFarmlandArea': null, // 旱田面积
    'equipmentInfoList': [
      // 生产设备信息List
      {
        'name': '',
        // 设备名称
        'number': null,
        // 设备数量
        'sort': 0,
        'type': null
        //   设备类型 1:实验设备 2:农机设备
      }
    ],
    'facilitiesInfoList': [
      {
        'area': null,
        // 面积
        'buildTime': '',
        // 建设时间
        'isRecord': true,
        // 是否备案 1:是 0:否
        'recordTime': '',
        // 备案时间
        'type': 1
        // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
      }
    ],
    'landArea': null,
    // 配套用地总面积（亩）
    'landContractImg': '',
    //土地流转合同
    'landPlaneImg': '',
    // 土地流转合同
    'lat': '',
    // 经度
    'lng': '',
    // 纬度
    'methodAcquisition': null,
    // 土地取得方式 1:租赁 2:购买
    'otherArea': null,
    // 其他配套设施总面积（m2)
    'otherLandArea': null,
    // 其他面积
    'paddyFieldArea': null,
    // 水田面积
    'partyaName': '',
    // 甲方名称
    'partybName': '',
    // 乙方名称
    'planeImg': '',
    // 基地平面照片
    'provinceId': '',
    // 基地省id
    'provinceName': '',
    // 基地省名称
    'sort': 0,
    'villageId': '',
    // 基地村id
    'villageName': ''
    // 基地村名称
  }
];

export const facilitiesInfoList = [
  // 配套设施类型List
  {
    'area': null,
    // 面积
    'buildTime': '',
    // 建设时间
    'isRecord': true,
    // 是否备案 1:是 0:否
    'recordTime': '',
    // 备案时间
    'type': null
    // 配套设施类型 1:围墙 2:道路 3:晒场 4:大棚 5:其他
  }
];
export const equipmentInfoList =
  // 生产设备信息List
  {
    'name': '',
    // 设备名称
    'number': null,
    // 设备数量
    'sort': 0,
    'type': null
    //   设备类型 1:实验设备 2:农机设备
  };

export default baseInfo;
