const basisInfo = {
  'RegistNanfanAddress': '', // 注册地址
  'changeNanfanAddress': '', // 选择地址
  // 基础信息
  "contactName": "", // 联系人姓名
  "contactNumber": "", // contactNumber
  "email": "", // 邮箱
  "hainanWorkerNumber": null, // 海南工人数
  "identityCard": "", // 身份证号码
  "legalIdentityCard": "", // 法人身份证号
  "legalPerson": "", //  法人姓名
  "level": null, // 单位级别 1:国家级 2:省级 3:地级市 4:其他
  "managePersonalNumber": null, // 管理人员数
  "name": "", // 单位名称/姓名
  "nanfanAddress": "", // 南繁地址
  "nanfanCityId": "",  // 南繁地址市id
  "nanfanCityName": "",
  //南繁地址市名称
  "nanfanDirection": null,
  // 南繁方向: 1科研育种 2种子生产
  "nanfanDistrictId": "",
  // 南繁地址区id
  "nanfanDistrictName": "",
  // 南繁地址区名称
  "nanfanProvinceId": "",
  // 南繁地址省id
  "nanfanProvinceName": "",
  // 南繁地址省名称
  "otherPersonalNumber": null,
  // 其他人员数
  "otherWorkerNumber": null,
  // 外省工人数
  "personalNumber": null,
  // 人员总数
  "personnelInfoList": [
    // 重要人员信息
    {
      "activityEndDate": "",
      //南繁活动结束时间
      "activityStartDate": "",
      // 南繁活动开始时间
      "name": "", // 姓名
      "phoneNumber": "", // 联系电话
      "position": "", // 职务职称
      "sort": null // 排序
    }
  ],
  "phoneNumber": "",
  // 联系电话
  "position": "",
  // 职务职称
  "registerCityId": "",
  // 注册地址市id
  "registerCityName": "",
  // 注册地址市名称
  "registerDistrictId": "",
  // 注册地址区ids
  "registerDistrictName": "",
  // 注册地址区名称
  "registerProperty": null,
  // 登记属性: 1:科研单位 2:高等院校 3:企业 4:个人 5:其他
  "registerProvinceId": "",
  // 注册地址省id
  "registerProvinceName": "",
  //  注册地址省名称
  "skillPersonalNumber": null,
  // 专业技术人员数
  "societyCode": "",
  // 信用代码null
  "unitAddress": "",
  // 单位注册地址
  "workerNumber": null,
  // 工人总数
  "year": null
  // 登记年份
}

export default basisInfo
