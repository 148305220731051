export const role_list = [
  {
    id: 1,
    name: '科研'
  },
  {
    id: 2,
    name: '后勤'
  },
  {
    id: 3,
    name: '服务'
  },
  {
    id: 0,
    name: '其他'
  }
];
export const baseType = [
  {
    id: 0,
    name: '请选择'
  },
  {
    id: 1,
    name: '固定'
  },
  {
    id: 2,
    name: '临时'
  }
];
export const buildType = [
  {
    id: 1,
    name: '生活配套设施'
  },
  {
    id: 2,
    name: '生产/科研配套设施'
  }
];
export const nanfanType = [
  {
    id: true,
    name: '是'
  },
  {
    id: false,
    name: '否'
  }
];
//   1水稻 2玉米 3棉花 4大豆 5瓜菜 6茄果 7绿叶菜 0其它
export const cropInfoListType = [
  {
    id: 1,
    name: '水稻'
  },
  {
    id: 2,
    name: '玉米'
  },
  {
    id: 3,
    name: '常规棉花'
  }, {
    id: 10,
    name: '杂交棉花'
  },
  {
    id: 4,
    name: '大豆'
  },
  {
    id: 5,
    name: '瓜菜'
  },
  {
    id: 6,
    name: '茄果'
  },
  {
    id: 7,
    name: '绿叶菜'
  },
  {
    id: 8,
    name: '杂交水稻'
  }, {
    id: 9,
    name: '普通水稻'
  },
  {
    id: 0,
    name: '其他'
  }
];
//   1加代 2繁育 3鉴定 0其它
export const activityType = [{
  id: 1,
  name: '加代'
},
  {
    id: 2,
    name: '繁育'
  }, {
    id: 3,
    name: '鉴定'
  }, {
    id: 0,
    name: '其他'
  }];
export const breadLists = [
  {
    name: '基地信息'
  },
  {
    name: '基础信息'
  },
  {
    name: '活动信息'
  },
  {
    name: '成果信息'
  }
];
export const laborUnitPrice = [
  { id: 0, name: '100-200' },
  { id: 1, name: '200-1000' },
  { id: 2, name: '1000-3000' },
  { id: 3, name: '3000-5000' },
  { id: 4, name: '5000-10000' },
  { id: 5, name: '10000以上' }
];
