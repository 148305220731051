const cropInfoList = [
// 作物信息List
  {
    'breedingNumber': Number,
// 育种组合数
    'cropName': '',
// 作物名称
    'cropId': '',
// 作物名称
    'isTransgene': '',
// 是否转基因 1:是 0:否
    'plantArea': Number,
// 种植面积
    'plantEndTime': '',
// 种植结束时间
    'plantStartTime': '',
// 种植开始时间
    'project': Number,
// 项目 1:加代 2:扩繁 3:制种 4:鉴定 5:资源保存 6:其他
    'sort': 0,
    'varietiesNumber': Number,
// 品种数
    'yield': Number
//   产量
  }
];

export default cropInfoList;
